<template>
  <van-action-sheet v-model="isShow" title="选择性别：" cancel-text="取消" :actions="actions" @select="onSelected" lazy-render safe-area-inset-bottom/>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '选择性别:' },
    value: { type: String, default: '' }
  },
  data() {
    return {
      isShow: false,
      actions: [
        { name: '男', value: 'M' },
        { name: '女', value: 'F' }
      ],
      params: {}
    }
  },
  methods: {
    show(params) {
      this.params = params || {}
      this.isShow = true
    },
    onSelected(item) {
      this.$emit('input', item.value)
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
