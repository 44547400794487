<template>
  <div class="page-ctx">
    <van-cell-group title="用户信息">
      <van-field :value="fmData.phone" label="手机号" readonly input-align="right" />
      <van-field v-model="fmData.name" label="姓名" input-align="right" maxlength="20" />
      <van-field :value="fmData.sex | dictSex" label="性别" readonly input-align="right" @click="$refs.choiceSex.show()" />
      <van-field v-model="fmData.residentId" label="身份证" input-align="right" maxlength="18" />
      <van-field :value="fmData.createdAt | datetime" label="注册日期" readonly input-align="right" />
    </van-cell-group>

    <div class="btns-col" style="padding:var(--mp)">
      <van-button type="primary" @click="custUpdate">更新用户信息</van-button>
      <van-button type="warning" @click="logout">退出账号</van-button>
    </div>

    <ChoiceSex ref="choiceSex" v-model="fmData.sex"/>
  </div>
</template>

<script>
import CustApi from '@/apis/CustApi'
import ChoiceSex from '@/components/ChoiceSex'
export default {
  components: { ChoiceSex },
  data() {
    return {
      fmData: {}
    }
  },
  created() {
    this.fmData = this.$store.state.auth.user
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.replace('/login')
    },
    custUpdate() {
      CustApi.updateMyInfo(this.fmData).then(rsp => {
        this.$store.commit('auth/SET_USER', rsp.data)
        this.$toast.success('更新成功！')
      })
    }
  }
}
</script>

<style scoped>

</style>
